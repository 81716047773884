<template>
  <div class="artist-profile">
    <!-- <Loader :loading="loading" /> -->
    <div class="artist-profile__header">
      <h1 class="artist-profile__header_title">{{ artist.name }}</h1>
    </div>

    <div class="artist-profile__body">
      <div v-html="artist.biography" class="artist-profile__body_biography collect-editor"></div>
    </div>

    <div v-if="showRelatedArtworks" class="artist-profile__artworks_container">
      <div
        class="artist-profile__artworks_items-group"
        :class="{ 'artist-profile__artworks_items-group--two': relatedArtworks.length < 3 }"
      >
        <div v-for="artwork in relatedArtworks" :key="`artwork-${artwork.id}`" class="artwork">
          <router-link :to="{ path: `/art/${artwork.url}` }" class="artwork__link">
            <img
              class="artwork__image"
              :src="artwork.images[0]"
              :alt="`${artist.name} Related Art Pieces - ${artwork.title}`"
              decoding="async"
              loading="lazy"
            />
            <span class="artwork__title" v-if="artwork.is_show_title">{{ artwork.title }}</span>
          </router-link>
        </div>
      </div>
    </div>

    <div class="navigation__container">
      <div
        v-if="nextArtist !== null || previousArtist !== null"
        class="navigation__container_buttons-box"
      >
        <BaseButton
          v-if="previousArtist !== null"
          class="button--white button--ellipse button--xl"
          text="Previous Creator"
          @click.native="$router.push({ path: `/creators/${previousArtist.slug}` })"
        />

        <BaseButton
          v-if="nextArtist !== null"
          class="button--white button--ellipse button--xl"
          text="Next Creator"
          @click.native="$router.push({ path: `/creators/${nextArtist.slug}` })"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Loader from "@/components/Loader.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";

export default {
  components: {
    Loader,
    BaseButton,
  },

  data() {
    return {
      loading: false,
    };
  },
  head: {
    title: function () {
      return {
        inner: `GH - ${this.artist?.name ? this.artist.name : "Creator"}`,
        separator: " ",
        complement: " ",
      };
    },
    meta: function () {
      return [];
    },
  },
  computed: {
    ...mapGetters("publicArtists", {
      artist: "getSpecificArtist",
      nextArtist: "getNextArtist",
      previousArtist: "getPreviousArtist",
      relatedArtworks: "getRelatedArtworks",
    }),

    showRelatedArtworks() {
      if (this.artist.name === "Non-existent Creator" || this.relatedArtworks === null) {
        return false;
      } else {
        return true;
      }
    },
  },

  async created() {
    this.loading = true;
    await this.apiGetSpecificArtist(this.$route.params.slug);

    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Creators",
        to: "/creators",
      },
      {
        title: `${this.artist?.name}`,
        to: `/creators/${this.$route.params.slug}`,
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
    this.loading = false;
  },
  watch: {
    "$route.path": async function () {
      this.loading = true;
      await this.apiGetSpecificArtist(this.$route.params.slug);

      const breadcrumbs = [
        {
          title: "Home",
          to: "/",
        },
        {
          title: "Creators",
          to: "/creators",
        },
        {
          title: `${this.artist?.name}`,
          to: `/creators/${this.$route.params.slug}`,
        },
      ];
      this.setBreadcrumbs(breadcrumbs);
      this.loading = false;
    },
  },
  methods: {
    ...mapActions("publicArtists", ["apiGetSpecificArtist"]),
    ...mapMutations(["setBreadcrumbs"]),
  },
};
</script>

<style lang="scss" scoped>
* {
  color: $white;
}

.artist-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $lightBlack;
  min-height: 100vh;
  min-width: 100vw;
  font-size: 2.1rem;
  line-height: 3.5rem;
  @media (max-width: $xxs) {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }

  &__header {
    margin-top: 15.6rem;
    @media (max-width: $xxs) {
      margin-top: 28.3rem;
    }

    &_title {
      display: inline-block;
      text-align: center;
      font-family: $fontBodoni;
      font-size: 6.2rem;
      line-height: 6.8rem;
      @media (max-width: $xxs) {
        font-size: 4rem;
        line-height: 6rem;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    width: 105rem;
    padding: 4.4rem 5vw 3.2rem;
    @media (max-width: $md) {
      width: 100%;
      padding: 4.4rem 6rem 3rem;
    }
    @media (max-width: $xxs) {
      padding: 6.7rem 2.8rem 4.7rem;
    }

    &_biography::v-deep * {
      color: $white !important;
    }
  }

  &__artworks {
    &_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 0 5rem;
      @media (max-width: $xxs) {
        align-items: center;
        padding: 0 2.8rem;
      }
    }

    &_items-group {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      &--two {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: $sm) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: $xs) {
        width: 100%;
        grid-template-columns: 1fr;
      }

      .artwork {
        max-width: 32.8rem;
        padding: 4rem 1.4rem 0rem;
        @media (max-width: $xs) {
          max-width: 86.5vw;
          padding: 4rem 0rem 0rem;
        }
        &__link {
          display: block;
          text-decoration: none;
        }
        &__image {
          height: 30rem;
          width: 30rem;
          object-fit: cover;
          cursor: pointer;
          @media (max-width: $xs) {
            width: 100%;
            height: 85vw;
          }
        }

        &__title {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-clamp: 2;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
  .navigation__container {
    display: flex;
    flex-direction: column;
    margin: 16.5rem 0rem 10.4rem;
    padding-left: 30%;
    width: 100%;
    @media only screen and (max-width: $xs) {
      padding-left: 15%;
    }
    @media only screen and (max-width: $xxs) {
      margin: 9.3rem 0rem 18.9rem;
      padding-left: 2.6rem;
    }
    &_title {
      font-size: 3.8rem;
      line-height: 4.8rem;
      margin: 0rem 0rem 5rem 1.2rem;
      font-family: $fontBodoni;
      @media only screen and (max-width: $xxs) {
        font-size: 2.4rem;
        line-height: 3rem;
        margin: 0rem 0rem 3rem 0rem;
      }
    }
    &_buttons-box {
      display: flex;
      align-items: center;
      @media only screen and (max-width: $xxs) {
        flex-wrap: wrap;
      }
      &::after {
        content: "";
        background-color: $white;
        height: 1px;
        flex-grow: 100;
      }
      .button {
        width: max-content;
        margin-left: 10px;
        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }
}
</style>

